import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  Text,
  Flex,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";
import Logo from "../Logo";
import Link from "next/link";
import LanguageSwitcher from "../../LanguageSwitcher";

import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import SocialMediaIcon from "../SocialMediaIcon";
import React from "react";

const footerData = [
  {
    title: "Kurumsal",
    links: [
      { href: "/about", label: "Hakkımızda" },
      { href: "/our-references", label: "Referanslarımız" },
      { href: "/commercial-information", label: "Ticari Bilgiler" },
      { href: "/careers", label: "Kariyer" },
      { href: "/contact", label: "İletişim" },
    ],
  },
  {
    title: "Hizmetler",
    links: [
      { href: "/services", label: "Yazılım Hizmetlerimiz" },
      { href: "/services", label: "Dijital Hizmetlerimiz" },
      { href: "/services", label: "Grafik Tasarım Hizmetlerimiz" },
      { href: "/services", label: "E-ticaret Çözümleri" },
    ],
  },
  {
    title: "Blog / RSS",
    links: [
      { href: "/blog", label: "Blog" },
      { href: "/rss", label: "RSS" },
    ],
  },
  {
    title: "Legal",
    links: [
      { href: "/cookie-policy", label: "Çerez Politikası" },
      { href: "/privacy-policy", label: "Gizlilik Politikası" },
    ],
  },
];

const ListHeader = ({ children }) => {
  return (
    <Text
      fontWeight={"500"}
      fontSize={"lg"}
      mb={2}
      textDecoration={"underline"}
    >
      {children}
    </Text>
  );
};

const FooterAccordion = () => {
  return (
    <Flex align={"center"} justify={"center"}>
      <Container>
        <Accordion width="100%" maxW="full" rounded="lg" allowToggle>
          {footerData.map((section) => (
            <AccordionItem key={section.title + "_title"}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        {section.title}
                      </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize="12px" />
                      ) : (
                        <AddIcon fontSize="12px" />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Stack align={"flex-start"}>
                      {section.links.map((link, index) => (
                        <Link key={`${link.href}_${index}`} href={link.href}>
                          {link.label}
                        </Link>
                      ))}
                    </Stack>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </Container>
    </Flex>
  );
};

function LargeWithLogoCentered() {
  return (
    <Flex direction={"column"}>
      <Box
        bgImage={"linear-gradient(220deg, #38435B 46%, #495368 100%)"}
        color={useColorModeValue("white", "gray.200")}
        w="100vw"
        h="auto"
      >
        <Flex direction={"column"} justify={"center"} justifyContent={"center"}>
          <Container as={Stack} maxW={"8xl"} py={{ base: 5, sm: 10 }}>
            <SimpleGrid
              columns={{ base: 1, sm: 2, md: 4 }}
              spacing={8}
              alignItems="center"
              justifyItems="center"
              display={{ base: "none", md: "grid" }}
            >
              {footerData.map((section) => (
                <Stack key={section.title} align={"flex-start"}>
                  <ListHeader>{section.title}</ListHeader>
                  {section.links.map((link, index) => (
                    <Link key={`${link.href}_${index}`} href={link.href}>
                      {link.label}
                    </Link>
                  ))}
                </Stack>
              ))}
            </SimpleGrid>
            <Box display={{ base: "block", md: "none" }}>
              <FooterAccordion />
            </Box>
          </Container>

          <Box py={{ base: 0, sm: 10 }}>
            <Flex
              align={"center"}
              _before={{
                content: '""',
                borderBottom: "1px solid",
                borderColor: useColorModeValue("gray.200", "gray.200"),
                flexGrow: 1,
                mr: 8,
              }}
              _after={{
                content: '""',
                borderBottom: "1px solid",
                borderColor: useColorModeValue("gray.200", "gray.200"),
                flexGrow: 1,
                ml: 8,
              }}
            >
              <Logo position="footer" isLink={false} />
            </Flex>
            <Box pt={{ base: 0, sm: 6 }} fontSize={"sm"} textAlign={"center"}>
              <SocialMediaIcon />
            </Box>
            <Box pt={{ base: 0, sm: 6 }} fontSize={"sm"} textAlign={"center"}>
              <FooterData />
            </Box>
            <Box pt={{ base: 4, sm: 6 }} fontSize={"sm"} textAlign={"center"}>
              <LanguageSwitcher />
            </Box>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
}

const FooterData = () => {
  const startYear = 2023;
  const currentYear = new Date().getFullYear();
  let yearText;
  if (currentYear > startYear) {
    yearText = `${startYear}-${currentYear}`;
  } else {
    yearText = `${startYear}`;
  }
  return <> {yearText} &copy; Tüm Hakları Saklıdır.</>;
};

export default React.memo(LargeWithLogoCentered);
