import { Box, Select, useColorModeValue } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { locales } from "../locales";

const routes = require("../routes");

const getRouteSource = (name, language) => {
  if (routes[language] && routes[language][name]) {
    return routes[language][name].config.rewrite.source;
  }
  return null;
};

function LangSwitcher() {
  const router = useRouter();
  const { locale, pathname, query } = router;
  const [currentLocale, setCurrentLocale] = useState(locale);

  useEffect(() => {
    const savedLocale = localStorage.getItem("selectedLocale") || locale;
    setCurrentLocale(savedLocale);

    const broadcastChannel = new BroadcastChannel("lang-switch");

    const handleMessage = (message) => {
      if (message.data !== currentLocale) {
        setCurrentLocale(message.data);
      }
    };

    broadcastChannel.postMessage(savedLocale);
    broadcastChannel.addEventListener("message", handleMessage);

    return () => {
      broadcastChannel.removeEventListener("message", handleMessage);
      broadcastChannel.close();
    };
  }, [locale, currentLocale]);

  const changeLanguage = (newLocale) => {
    const newRoute = getRouteSource(pathname, newLocale);
    setCurrentLocale(newLocale);
    localStorage.setItem("selectedLocale", newLocale);

    if (newRoute) {
      router.push({
        pathname: newRoute,
        query: { ...query },
      }, undefined, { locale: newLocale, shallow: false });
    } else {
      router.push({
        pathname: pathname,
        query: { ...query },
      }, undefined, {
        locale: newLocale,
        shallow: false,
      });
    }

    const broadcastChannel = new BroadcastChannel("lang-switch");
    broadcastChannel.postMessage(newLocale);
    broadcastChannel.close();
  };

  const bgColor = useColorModeValue("white", "gray.700");
  const color = useColorModeValue("black", "white");

  return (
    <Box>
      <Select
        value={currentLocale}
        onChange={(e) => changeLanguage(e.target.value)}
        width="170px"
        variant="outline"
        bg={bgColor}
        color={color}
        borderColor={useColorModeValue("gray.200", "gray.600")}
        _hover={{
          borderColor: useColorModeValue("gray.300", "gray.500"),
        }}
      >
        {Object.values(locales)
          .filter((locale) => locale.active)
          .map((locale) => (
            <option key={locale.hl} value={locale.hl}>
              {locale.name}
            </option>
          ))}
      </Select>
    </Box>
  );
}

export default LangSwitcher;
